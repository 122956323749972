import React, {useEffect, useState} from "react";
import axios from "axios";
import {observer, inject} from 'mobx-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faSpinner,
    faPencilAlt,
    faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import {faSync} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../components/modal';
import Checkbox from "../../components/checkbox";
import GlNatural from "../../components/gl-natural";
import AdminNavigationBar from "../../components/admin-navigation-bar";
import FilterInputField from "../../components/filter-input-field";
import PaginationControls from "../../components/pagination-controls";
import { fetch } from '../../utils/api-wrapper';

const AdminGlNaturals = ({data}) => {
    const source = axios.CancelToken.source();
    const [glNaturals, setGlNaturals] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showImportModal, setShowImportModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [sorting, setSorting] = useState([{"id": "GLNatural", "desc": false}]);
    const [filters, setFilters] = useState([]);
    const [globalFilter, setGlobalfilter] = useState("");
    const [selected, setSelected] = useState(null);
    const [importFile, setImportFile] = useState(null);
    const [savingError, setSavingError] = useState(null);
    const [glNaturalsDeleteSelection, setGlNaturalsDeleteSelection] = useState([]);
    const [glNaturalsDeleteConfirm, setGlNaturalsDeleteConfirm] = useState(false);
    const [filterValue, setFilterValue] = useState("");
    


    const processImport = () => {
        if (importFile != null) {
            const dataForm = new FormData();
            dataForm.append("csv", importFile);

            fetch(  "/gl_naturals/import", "POST", dataForm,
                response => {
                    setImportFile(null);
                    setShowImportModal(false);
                },
                (error) => {
                    setSavingError(data.t.read("error"));
                    setImportFile(null)
                }, true, source.token
            )
        }
    }

    const availableColumns = [
        {"name": "GLNatural", "evaluate": inv => inv.GLNatural, "displayName": "gl_natural"},
        {
            "name": "budget",
            "evaluate": inv => inv.budget ? `$ ${inv.budget.toLocaleString(`${data.t.lang}-${data.t.lang.toUpperCase()}`, {maximumFractionDigits: 2})}` : "",
            "displayName": "budget"
        },
        {
            "name": "category",
            "evaluate": inv => inv.category ? data.t.read(`gl_category.${inv.category.value}`) : "",
            "displayName": "category"
        },
        {"name": "label", "evaluate": inv => inv.label ? inv.label : "", "displayName": "label"},
        {
            "name": "active",
            "evaluate": inv => inv.active && inv.active === true ? data.t.read("rule.active") : data.t.read("rule.inactive"),
            "displayName": "client.users.active"
        },
    ]

    const search = () => {
        let filter = {"id": "GLNatural", "value": filterValue}
        const result = [...filters.filter(f => filter.id !== f.id), filter];
        setFilters(result);
    }

    const isAllGlNaturalsSelected = () => {
        if (!glNaturals || !glNaturals.length)
            return false;
        // Check if every office in the offices array is included in officesDeleteSelection
        return glNaturals.every(glNatural => glNaturalsDeleteSelection.includes(glNatural._id?.$oid));
    }

    const toggleAllGlNaturalsSelected = () => {

        setGlNaturalsDeleteSelection(isAllGlNaturalsSelected() ?
            // If all glnaturals are currently selected, unselect all glnaturals
            glNaturalsDeleteSelection.filter(id => !glNaturals.some(glNatural => glNatural._id?.$oid === id ))
            :
            // Else, select all GlNaturals without duplicating existing selections
            [...glNaturalsDeleteSelection, ...glNaturals.map((glNatural) => glNatural._id?.$oid)]
        )
    }
    const isGlNaturalSelected = (gl) => {
        return glNaturalsDeleteSelection.find(x => x === gl._id?.$oid) !== undefined;
    }
    const deleteGlNaturals = () => {
        setSaving(true);
        fetch(`/gl_naturals/`, "DELETE", {"gl_natural_ids": glNaturalsDeleteSelection},
            response => {
                fetchGlNaturals();
                setGlNaturalsDeleteSelection([]);
                setGlNaturalsDeleteConfirm(false);
                setSaving(false);
            }
            , () => {
                setSaving(false);
                setGlNaturalsDeleteConfirm(false);
            }, false, source.token);
    }

    const fetchGlNaturals = () => {
        let start = (page-1) * pageSize;

        if (globalFilter.length !== 0 || filters.length !== 0)
            start = 0;

        const searchData = `?start=${encodeURIComponent(start)}&page_size=${encodeURIComponent(pageSize)}&globalfilter=${encodeURIComponent(globalFilter ?? '')}&filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;

        fetch(`/gl_naturals/${searchData}`, "GET", null, (response) => {
                setGlNaturals(response.data.gl_naturals);
                setLoading(false);
                setTotalRowCount(response.data.meta.totalRowCount);
            },
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchGlNaturals()
            }
            , false, source.token);
    }

    useEffect(() => {
            fetchGlNaturals();
        }
        , [])

    useEffect(() => {
        return () => {
            source.cancel('gl_naturals list unmounted');
        }
    }, [])

    useEffect(() => {
        fetchGlNaturals();
    }, [page, sorting, filters, pageSize])

    useEffect(() => {
        setPage(1)
    },[filterValue]);

    const sortTable = (name) => {
        if (saving) {
            return;
        }
        const sorter = sorting.find(s => s.id === name)

        if (sorter === undefined)
            return setSorting([{"id": name, "desc": true}])

        if (sorter.desc)
            return setSorting([{"id": name, "desc": false}])
        else
            return setSorting([])
    }

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (rows) => {
        setPageSize(rows)
        setPage(1)
    }

    //pagination logic
    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + glNaturals.length - 1;
    const totalPages = Math.ceil(totalRowCount / pageSize);
    const emptyRowsCount = Math.max(0, pageSize - glNaturals.length);
    const emptyRows = Array(emptyRowsCount).fill({});

    return (
        <div className="card pl-4 pr-4 pb-3 rounded-lg">
            <AdminNavigationBar data={data} adminPage={"gl_natural"}></AdminNavigationBar>
            <div>
                {!loading ?
                    savingError != null ?
                        <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                            <div className="text-danger">
                                {savingError}
                            </div>
                        </Modal>
                        :
                        <div>
                            <div>
                                <div className='d-flex justify-content-between p-2 align-content-center'>
                                    <FilterInputField
                                        filterValue={filterValue} setFilterValue={setFilterValue}  placeholder={""} search={search}>
                                    </FilterInputField>
                                    <PaginationControls
                                        page={page}
                                        totalPages={totalPages}
                                        indexOfFirstItem={indexOfFirstItem}
                                        indexOfLastItem={indexOfLastItem}
                                        handlePageChange={handlePageChange}
                                        totalItems={totalRowCount}
                                        data={data}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        rowsPerPage={pageSize}
                                    ></PaginationControls>
                                </div>
                                <div className="d-flex justify-content-end align-items-center px-4 py-2">
                                    <div className="d-flex">
                                        <button
                                            type="button"
                                            className=' pointer btn btn-secondary btn-sm ml-2'
                                            disabled={saving || glNaturalsDeleteSelection.length === 0}
                                            onClick={() => setGlNaturalsDeleteConfirm(true)}>
                                            {data.t.read("delete") + " (" + glNaturalsDeleteSelection.length + ")"}
                                            {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync}/>}
                                        </button>
                                        <button
                                            onClick={() => setShowImportModal(!showImportModal)}
                                            type="button"
                                            className='btn btn-primary btn-sm ml-2'>
                                            {data.t.read("import")}
                                        </button>
                                        <button onClick={() => setSelected({})} type="button"
                                                className='btn btn-primary btn-sm ml-2'>
                                            {data.t.read("add")}
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-2 card rounded-lg admin-table">

                                    <table className='table table-hover font-small'>
                                        <thead>
                                        <tr>
                                            <th className="col-1">
                                                <Checkbox disabled={!glNaturals || !glNaturals.length}
                                                          isChecked={isAllGlNaturalsSelected()}
                                                          onChange={toggleAllGlNaturalsSelected}/>
                                            </th>
                                            {availableColumns.map((availableColumn) => (
                                                <th style={{width: "16%"}} className='pointer col-2'
                                                    onClick={() => sortTable(availableColumn.name)}
                                                    key={`header-${availableColumn.displayName}}`}>
                                                    {data.t.read(`${availableColumn.displayName}`)}
                                                    {sorting.find(s => s.id === availableColumn.name) &&
                                                        <FontAwesomeIcon
                                                            className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                            icon={sorting.find(s => s.id === availableColumn.name).desc ? faArrowDown : faArrowUp}/>}
                                                </th>
                                            ))}
                                            <th className="col-1">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            glNaturals.map(inv =>
                                                <tr key={`${inv._id?.$oid}-list`}>
                                                    <td className="col-1">
                                                        <Checkbox isChecked={isGlNaturalSelected(inv)}
                                                                  onChange={e => isGlNaturalSelected(inv) ? setGlNaturalsDeleteSelection(glNaturalsDeleteSelection.filter(x => x != inv._id?.$oid)) : setGlNaturalsDeleteSelection([...glNaturalsDeleteSelection, inv._id?.$oid])}/>
                                                    </td>

                                                    {availableColumns.map(availableColumn =>
                                                        <td className="col-2" onClick={() => setSelected(inv)}
                                                            key={`${inv._id?.$oid}-${availableColumn.displayName}-value`}>
                                                            {availableColumn.evaluate(inv)}
                                                        </td>)}

                                                    <td className="col-1">
                                                        {<FontAwesomeIcon className="mr-3 ml-2"
                                                                          onClick={() => setSelected(inv)}
                                                                          icon={faPencilAlt}/>}
                                                        {<FontAwesomeIcon style={{color: 'red'}}
                                                                          onClick={() => {
                                                                              setGlNaturalsDeleteSelection([inv._id?.$oid]);
                                                                              setGlNaturalsDeleteConfirm(true);
                                                                          }}
                                                                          icon={faWindowClose}/>}
                                                    </td>
                                                </tr>
                                            )}
                                        {
                                            emptyRows.map((_, idx) => (
                                                <tr key={`empty-${idx}`}>
                                                    <td colSpan="7">&nbsp;</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            {
                                glNaturalsDeleteConfirm == true ?
                                    <Modal title={data.t.read("confirm")}
                                           cancelAction={() => setGlNaturalsDeleteConfirm(false)}
                                           okAction={() => deleteGlNaturals()} okText={data.t.read("delete")}
                                           cancelText={data.t.read("cancel")}>
                                        {data.t.read("delete")} ?
                                    </Modal>
                                    :
                                    null
                            }
                            {
                                selected !== null ?
                                    <div className="overlay" onClick={() => setSelected(null)}>
                                        <div onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation()
                                        }}>
                                            <GlNatural config={data.config} glNatural={selected} t={data.t}
                                                       close={() => {
                                                           setSelected(null);
                                                           fetchGlNaturals();
                                                       }}/>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                showImportModal &&
                                <Modal width="50vw" title={data.t.read("supplier_table.importation")}
                                       cancelAction={() => setShowImportModal(null)}
                                       okAction={() => processImport()}
                                       okText={data.t.read("import")} cancelText={data.t.read("cancel")}>
                                    <div>
                                        {data.t.read("csv_file_format_gl")}
                                        <div className='card p-2'>
                                            {data.t.read("gl_code_details")}<br/>
                                            {data.t.read("gl_code_details")}<br/>
                                            {data.t.read("gl_code_details")}<br/>
                                        </div>
                                        <div className='mt-2'>
                                            <input className='form-control' type="file" id="import-csv-file"
                                                   onChange={f => f.target.files && f.target.files.length > 0 && setImportFile(f.target.files[0])}/>
                                        </div>
                                    </div>
                                </Modal>
                            }
                        </div>
                    :
                    <div className="d-flex loading-container w-100">
                        <FontAwesomeIcon className="infinite-rotate" icon={faSpinner}/>
                    </div>
                }
            </div>
        </div>)
}

export default inject('data')(observer(AdminGlNaturals));