import React, {useEffect, useRef, useState} from 'react';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faPencilAlt,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {fetch} from '../utils/api-wrapper';
import Checkbox from "./checkbox";
import RuleEdit from "./rule-edit";
import Modal from "./modal";

const TableRules = (props) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [searchStr, setSearchStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [ruleLearnSelection, setRuleLearnSelection] = useState([]);
    const [selectedRule, setSelectedRule] = useState(null);

    const cId = useRef(0);
    const aId = useRef(0);
    const valId = useRef(0);

    const [dataset, setDataset] = useState([]);
    const columnWidth = `${85 / (props.availableColumns.length + 1)}%`;
    const [pageSize, setPageSize] = useState(props.pageSize ? props.pageSize : 5);
    const [page, setPage] = useState(1);
    const [totalRowCount, setTotalRowCount] = useState(0);

    const LearnRules = (rules) => {
        rules.forEach((rule) => {
            let data = new FormData();
            let tmp = JSON.stringify(rule);
            tmp = JSON.parse(tmp);
            delete tmp.saved
            tmp.conditions.forEach(c => {
                delete c.id
            })
            tmp.actions.forEach(a => {
                delete a.id
            })
            delete tmp.supplier

            data.append("rule", JSON.stringify(tmp));

            fetch("/rule", "POST", data,
                response => {
                    setSuccessMessage(props.data.t.read("learning_successful"))
                },
                error => {
                    setErrorMessage(props.data.t.read("learning_failure"));
                })
        })
        fetchData()
        setRuleLearnSelection([])
        setSelectedRule(null)
    }

    const fetchData = () => {
        setLoading(true);
        const searchData = `?filter=${encodeURIComponent(searchStr)}`;
        fetch(`/invoice_analyzer/${searchData}`, "GET", {}, response => {
            let proposedRules = response.data;
            let ruleId = 1;
            proposedRules.forEach(r => {
                r.id = ruleId++;
                r.saved = false;
                r.priority = 1;

                r.actions.forEach(a => {
                    if (a["type"] === "split_items") {
                        a.value.forEach(v => {
                            if (valId.current < v.id) {
                                valId.current = v.id + 1;
                            }
                        })
                    }
                })

                r.conditions.forEach(c => {
                    c.id = ++cId.current;
                })

                r.actions.forEach(a => {
                    a.id = ++aId.current;
                })
            })
            setDataset(proposedRules);
            setTotalRowCount(proposedRules.length);
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchData();
        setRuleLearnSelection([])
    }, [searchStr])

    const isAllRulesSelected = () => {
        if (!currentRules || !currentRules.length) {
            return false;
        }
        return currentRules.every(rule => ruleLearnSelection.includes(rule.id));
    }

    const isRuleSelected = (rule) => {
        return ruleLearnSelection.includes(rule.id);
    }

    const toggleAllRulesSelected = () => {
        setRuleLearnSelection(isAllRulesSelected() ?
            ruleLearnSelection.filter(id => !currentRules.some(rule => rule.id === id))
            :
            [...ruleLearnSelection, ...currentRules.map((rule) => rule.id).filter((id) => !ruleLearnSelection.includes(id))]
        );
    }

    //pagination logic
    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + pageSize - 1;
    const currentRules = dataset.slice(indexOfFirstItem, indexOfLastItem + 1);
    const totalPages = Math.ceil(dataset.length / pageSize);

    return <div className={'card col m-2 min-height-45 border-radius-30'}>
            <div className="card-body">
                <div className='row'>
                    {props.availableColumns.filter(c => c.searchable === true).map((availableColumn) => (
                        <input
                            key={`header-${availableColumn.displayName}}-search`}
                            type="text"
                            value={searchStr}
                            className="form-control search-field"
                            placeholder={props.data.t.read(availableColumn.displayName)}
                            onChange={e => setSearchStr(e.target.value)}
                        />
                    ))}
                    <button
                        className="ml-2 btn btn-primary btn-sm mr-2 border-radius-30"
                        type="button"
                        disabled={ruleLearnSelection.length === 0}
                        onClick={() => {
                            let rules = dataset.filter(rule => ruleLearnSelection.includes(rule.id));
                            LearnRules(rules)
                        }
                        }
                    >
                        {props.data.t.read("learn") + " (" + ruleLearnSelection.length + ")"}
                    </button>
                </div>
                <div className='pt-2 row align-items-center'>
                    <div className={"pr-2"}>
                        <Checkbox disabled={!dataset || !dataset.length}
                                  isChecked={isAllRulesSelected()}
                                  onChange={toggleAllRulesSelected}
                        />
                    </div>
                    {props.availableColumns.map((availableColumn) => (
                            <div
                                style={{width: availableColumn.displayName === 'description' ? `${2 * parseFloat(columnWidth)}%` :  columnWidth}}
                                className=' one-line '
                                key={`header-${availableColumn.displayName}`}>
                                <span
                                    className='font-weight-bold' data-bs-toggle="tooltip"
                                    title={availableColumn.acronym && props.data.t.read(availableColumn.acronym)}>
                                    {props.data.t.read(`${availableColumn.displayName}`)}
                                </span>
                            </div>
                        )
                    )}
                </div>
                {loading  ? 
                <div className="d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon className="infinite-rotate text-primary" icon={faSpinner} />
                </div> 
                :
                currentRules.map((rule, i) =>
                    rule.saved === false && <div className='row  pb-2 align-items-center' key={`${i}-list`}>
                            <div className={"pr-2"}>

                                <Checkbox isChecked={isRuleSelected(rule)}
                                          onChange={() => isRuleSelected(rule) ? setRuleLearnSelection(ruleLearnSelection.filter(x => x !== rule.id)) : setRuleLearnSelection([...ruleLearnSelection, rule.id])}/>
                            </div>
                            {props.availableColumns.map(availableColumn =>
                                <div
                                    onClick={() => setSelectedRule(rule)}
                                    style={{width: availableColumn.displayName === 'description' ? `${2 * parseFloat(columnWidth)}%` :  columnWidth}}
                                    className={["vendors", "gl", "invoices"].includes(availableColumn.displayName) ? 'pointer text-underline text-blue one-line p-1' : ' pointer one-line p-1'}
                                    key={`${i}-${availableColumn.displayName}-value`}>
                                <span className='font-small'>
                                    {availableColumn.evaluate(rule)}
                                </span>
                                </div>
                            )}
                            <div
                                style={{width: "10%"}}
                                key={`header-${"Learn"}`}
                            >
                                {<FontAwesomeIcon className="mr-2" icon={faPencilAlt}
                                                  onClick={() => setSelectedRule(rule)}
                                                  type="button"/>}
                                {<FontAwesomeIcon className="mr-2 text-primary" icon={faCheckCircle}
                                                  onClick={() => LearnRules([rule])}
                                                  type="button"/>}

                            </div>
                        </div>
                        
                )}
            </div> 
            
            <div className='d-flex justify-content-between p-2'>
                <div>
                <span className='font-italic'>
                    {indexOfFirstItem + 1}-{Math.min(indexOfLastItem + 1, totalRowCount)} {props.data.t.read("rule.of")}
                </span>
                    <span className='p-1 text-primary'>
                    {totalRowCount}
                </span>
                </div>
                <div>
                    <button className='btn  btn-sm' disabled={loading || page === 1}>
                        <FontAwesomeIcon key={"left-arrow-double"} className='fa-xs' onClick={() => setPage(1)}
                                         icon={faAngleDoubleLeft}/>
                    </button>
                    <button className='btn  btn-sm' disabled={loading || page === 1}>
                        <FontAwesomeIcon key={"left-arrow"} className='fa-xs' onClick={() => setPage(page - 1)}
                                         icon={faChevronLeft}/>
                    </button>
                    <span key={`page-${page}`} className={'active text-primary'}>
                    <span key={`page-n-${page}`} className='p-2'>{page}</span>
                </span>
                    <button className='btn btn-sm'
                            disabled={loading || page >= totalPages}>
                        <FontAwesomeIcon key={"right-arrow"} className='fa-xs' onClick={() => setPage(page + 1)}
                                         icon={faChevronRight}/>
                    </button>
                    <button className='btn btn-sm'
                            disabled={loading || page >= totalPages}>
                        <FontAwesomeIcon key={"right-arrow-double"} className='fa-xs'
                                         onClick={() => setPage(totalPages)}
                                         icon={faAngleDoubleRight}/>
                    </button>
                </div>
            </div>
        {
            selectedRule &&
            <div className="overlay" onClick={() => setSelectedRule(null)}>
                <div className='d-flex'>
                    <div onClick={e => {
                        e.stopPropagation()
                    }}>
                        <RuleEdit rule={selectedRule} data={props.data} learnRule={LearnRules} close={()=> {
                            setSelectedRule(null)
                        }} />
                    </div>
                </div>
            </div>
        }
        {errorMessage != null && (
            <Modal title="Attention" okText={"OK"} okAction={() => setErrorMessage(null)}>
                <p style={{ whiteSpace: 'pre-line' }} className='text-danger'>{errorMessage}</p>
            </Modal>
        )}
        {successMessage != null && (
            <Modal title="Attention" okText={"OK"} okAction={() => setSuccessMessage(null)}>
                {successMessage}
            </Modal>
        )}


        </div> 
}

export default TableRules;