import { useState } from "react";
import axios from "axios";
import { observer, inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Utils from "../utils/utils";
import Checkbox from "./checkbox";
import Checkmark from "./checkmark";
import Closemark from "./closemark";
import Modal from './modal';
import { fetch } from '../utils/api-wrapper';

const PerdiemAndMileageList = ({ data, expensesAccount, saving, setCurrentExpense, setSaving, fetchExpensesAccount, approveExpense, disApproveExpense, validateExpense }) => {
    const source = axios.CancelToken.source();
    const [xpensesDeleteSelection, setXpensesDeleteSelection] = useState([]);
    const [xpensesDeleteConfirm, setXpensesDeleteConfirm] = useState(false);

    const { account_id } = useParams(); // Access the id parameter from the URL
    const isAllXpensesSelected = () => {
        if (!expensesAccount || !expensesAccount.expenses)
            return false;

        return xpensesDeleteSelection.length > 1 && xpensesDeleteSelection.length === expensesAccount.expenses.filter(x => ['mileage', 'perdiem'].includes(x.json?.type)).length;
    }
    const toggleAllXpensesSelected = () => {
        xpensesDeleteSelection.length === expensesAccount.expenses.filter(x => ['mileage', 'perdiem'].includes(x.json?.type)).length ? setXpensesDeleteSelection([]) : setXpensesDeleteSelection([...expensesAccount.expenses.filter(x => ['mileage', 'perdiem'].includes(x.json?.type)).flatMap(x => x.checksum)]);
    }
    const isXpenseSelected = (xpens) => {
        return xpensesDeleteSelection.find(x => x === xpens.checksum) !== undefined;
    }
    const deleteXpenses = () => {
        fetch(`/expenses_account/expenses/${account_id}`, "DELETE", { "checksums": xpensesDeleteSelection },
            response => {
                fetchExpensesAccount();
                setXpensesDeleteSelection([]);
                setXpensesDeleteConfirm(false);
            }
            , null, false, source.token);
    }

    const addExpense = () => {
        setSaving(true);
        fetch(`/expenses_account/add/${account_id}`, "PUT", null,
            response => { setSaving(false); setCurrentExpense(response.data); },
            null, false, source.token
        )

    }

    return <><Accordion defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="xpenses-panel-content"
            id="xpenses-panel-header"
        >
            <h3>{data.t.read('perdiem_and_deplacement')}</h3>
        </AccordionSummary>
        <AccordionDetails>
            {data.isMobile && <div className="text-right">
                <button hidden={expensesAccount?.status !== "Pending"} type="button" className="mr-1 pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving | xpensesDeleteSelection.length === 0} onClick={() => setXpensesDeleteConfirm(true)}>
                    <div>
                        {data.t.read("delete")}
                        {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                    </div>
                </button>
                <button hidden={expensesAccount?.status !== "Pending"} type="button" className="btn  btn-sm  btn-primary btn-primary-shadow pb-2 pt-2" disabled={saving | expensesAccount?.status !== "Pending"} onClick={() => addExpense()}>
                        <div className="action-buttons action-buttons-sm">
                            {data.t.read("add_new")}
                            {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                        </div>
                </button>
            </div>}
            <div className="d-flex">
                <div className="col-8">
                    <table className="table table-borderless table-sm">
                        <thead>
                            <tr>
                                <th><Checkbox disabled={!expensesAccount || !expensesAccount.expenses} isChecked={isAllXpensesSelected()} onChange={toggleAllXpensesSelected} /></th>
                                <th className="col-2"></th>
                                <th className="text-center col-3">Xpense Type</th>
                                {!data.isMobile && <th className="text-center col-3">Xpense {data.t.read(`invoiceDate`)}</th>}
                                <th className="text-center col-3">{data.t.read(`invoice.total`)}</th>
                                {Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data, ['Admin', 'Approver']) && !data.isMobile && <th className="text-center">{data.t.read(`approval`)}</th>}
                                {!data.isMobile && <th className="text-center col-3">{data.t.read(`validate`)}</th>} 
                            </tr>
                        </thead>
                        <tbody>
                            {expensesAccount && expensesAccount.expenses.filter(x => ['mileage', 'perdiem'].includes(x.json?.type)).map(
                                (xpens, index) => <tr key={index}>
                                    <td><Checkbox isChecked={isXpenseSelected(xpens)} onChange={e => isXpenseSelected(xpens) ? setXpensesDeleteSelection(xpensesDeleteSelection.filter(x => x !== xpens.checksum)) : setXpensesDeleteSelection([...xpensesDeleteSelection, xpens.checksum])} /></td>
                                    <td></td>
                                    <td className="text-center" onClick={() => setCurrentExpense(xpens)} style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}>
                                        {data.t.read(xpens.json?.type)}
                                    </td>
                                    {!data.isMobile && <td className="text-center">
                                        {xpens.json?.InvoiceDate}
                                    </td>}
                                    <td className="text-center">
                                        {xpens.json && xpens.json?.BigTotalPayed && `$${xpens.json.BigTotalPayed.toFixed(2)}`}
                                    </td>
                                    {Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data, ['Admin', 'Approver']) && !data.isMobile &&
                                        <td>
                                            <div className="d-flex text-center justify-content-center">
                                                <Checkmark circle={true} callback={() => fetchExpensesAccount()} disabled={expensesAccount.status !== "Need_Approval" || !Utils.userHasPageAccess(data, "approval") || !Utils.userHasRole(data, ['Admin', 'Approver'])} data={data} isChecked={xpens.status === 'Approved'} onChecked={() => approveExpense(xpens)} />
                                                <Closemark callback={() => fetchExpensesAccount()} disabled={expensesAccount.status !== "Need_Approval" || !Utils.userHasPageAccess(data, "approval") || !Utils.userHasRole(data, ['Admin', 'Approver'])} data={data} isClosed={xpens.status === 'Denied'} onClosed={(causeDenial) => disApproveExpense(xpens, causeDenial)} />
                                            </div>
                                        </td>
                                    }
                                    {!data.isMobile && <td className="text-center">
                                        <Checkmark callback={() => fetchExpensesAccount()} disabled={expensesAccount.status === 'Pending'} isChecked={xpens.status === 'Validated'} onChecked={() => validateExpense(xpens)} />
                                    </td>}

                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {!data.isMobile && <div className="col-4 text-right">
                    <button hidden={expensesAccount?.status !== "Pending"} type="button" className="mr-1 pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving | xpensesDeleteSelection.length === 0} onClick={() => setXpensesDeleteConfirm(true)}>
                        <div>
                            {data.t.read("delete")}
                            {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                        </div>
                    </button>
                    <button hidden={expensesAccount?.status !== "Pending"} type="button" className="btn  btn-sm  btn-primary btn-primary-shadow pb-2 pt-2" disabled={saving | expensesAccount?.status !== "Pending"} onClick={() => addExpense()}>
                        <div className="action-buttons action-buttons-sm">
                            {data.t.read("add_new")}
                            {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                        </div>
                    </button>
                </div>
                }
            </div>
        </AccordionDetails>
    </Accordion>
        {
            xpensesDeleteConfirm === true &&
            <Modal title={data.t.read("confirm")} cancelAction={() => setXpensesDeleteConfirm(false)} okAction={() => deleteXpenses()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                {data.t.read("delete")} ?
            </Modal>

        }
    </>
}

export default inject('data')(observer(PerdiemAndMileageList));