import React, {useEffect, useRef, useState} from 'react';
import {fetch} from "../utils/api-wrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusSquare, faPlus} from "@fortawesome/free-solid-svg-icons";
import FormSelectUser from "./form-select-user";

const RuleEdit = (props) => {
    const [rule, setRule] = useState(null);
    const [selectedRule, setSelectedRule] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setSelectedRule(props.rule)
        fetchApprovers()
        setLoading(false);
    }, [])

    const [approvers, setApprovers] = useState([]);
    const [approver, setApprover] = useState(null);

    const cId = useRef(0);
    const aId = useRef(0);
    const valId = useRef(0);

    const fetchApprovers = () => {
        fetch("/client/users/Approver", "GET", {},
            response => {
                setApprovers(response.data);
            })
    }

    const addCondition = () => {
        let updated_rule = selectedRule;
        let c = {
            id: ++cId.current,
            type: "amount_greater",
            value: 1000,
        }
        updated_rule.conditions.push(c);
        updated_rule.saved = false;

        setRule({...updated_rule})
    }

    const addAction = () => {
        let updated_rule = selectedRule;
        let a = {
            id: ++aId.current,
            type: "send_email",
            value: "",
        }
        updated_rule.actions.push(a);
        updated_rule.saved = false;

        setRule({...updated_rule})
    }

    const updateRule = (property, event) => {
        let updated_rule = selectedRule;

        if (property === "active") {
            updated_rule.active = event.target.value === "1"
        } else if (property === "priority") {
            updated_rule.priority = parseInt(event.target.value)
        } else {
            updated_rule[property] = event.target.value;
        }

        updated_rule.saved = false;

        setRule({...updated_rule})
    }

    const updateCondition = (property, c, event) => {
        let updated_rule = {...selectedRule};
        updated_rule.saved = false;

        updated_rule.conditions.forEach(t => {
            if (c.id === t.id) {
                t[property] = event.target.value;

                if (property === "type" && event.target.value === "invoice_billable") {
                    t["value"] = "1";
                }
                if (property === "type" && event.target.value === "item_description") {
                    t["value"] = "";
                    t["operator"] = "contain"
                }
            }
        })

        setRule({...updated_rule})
    }

    const removeCondition = (c) => {
        let updated_rule = selectedRule;
        updated_rule.conditions.splice(updated_rule.conditions.indexOf(c), 1);
        updated_rule.saved = false;

        setRule({...updated_rule})
    }

    const updateAction = (property, a, event) => {
        let updated_rule = selectedRule;

        updated_rule.actions.forEach(t => {
            if (a.id === t.id) {
                if (property === "type" && event.target.value === "split_items") {
                    if (!Array.isArray(t["value"])) {
                        let val = [getSplitItemValue()];
                        t["value"] = val;
                    }
                } else if (property === "type" && event.target.value === "change_item_detail") {
                    t['field'] = 'GLNatural'
                } else {
                    t["value"] = "";
                }

                t[property] = event.target.value;
            }
        })
        updated_rule.saved = false;

        setRule({...updated_rule})
    }


    const removeAction = (c) => {
        let updated_rule = selectedRule;
        updated_rule.actions.splice(updated_rule.actions.indexOf(c), 1);
        updated_rule.saved = false;

        setRule({...updated_rule})
    }

    const getSplitItemValue = () => {
        return {
            id: ++valId.current,
            percent: 100,
            updateInfo: "",
            updateInfoValue: "",
            updateCharacterAmount: null,
            replaceAtTheStart: false,
            maxAmount: ""
        }
    }

    const addSplitItemValue = (action) => {
        let updated_rule = selectedRule;

        updated_rule.actions.forEach(t => {
            if (action.id === t.id) {
                let val = t.value;
                val.push(getSplitItemValue());
                t["value"] = val;
            }
        })
        updated_rule.saved = false;

        setRule({...updated_rule})
    }

    const updateSplitItemValue = (action, line, property, e) => {
        var str2bool = (value) => {
            if (value && typeof value === "string") {
                if (value.toLowerCase() === "true") return true;
                if (value.toLowerCase() === "false") return false;
            }
            return value;
        }

        let updated_rule = selectedRule;

        updated_rule.actions.forEach(t => {
            if (action.id === t.id) {
                t["value"].forEach(v => {
                    if (v === line) {
                        v[property] = str2bool(e.target.value);
                    }
                })
            }
        })
        updated_rule.saved = false;

        setRule({...updated_rule})
    }

    const removeSplitItemValue = (action, line) => {
        let updated_rule = selectedRule;

        updated_rule.actions.forEach(t => {
            if (action.id === t.id) {
                let val = t.value;
                val = val.filter(v => line !== v);
                t["value"] = val;
            }
        })
        updated_rule.saved = false;

        setRule({...updated_rule})
    }

    const handleSaveRule = (event) => {
        event.preventDefault();
        props.learnRule([selectedRule])
        let rule = selectedRule;
        rule.saved = true;
        setSelectedRule(rule)
    }

    return (
        <div className={"card col m-2 min-width-1000 min-height-70 border-radius-30"}>
            {!loading && <div className="card-body">
                <h3>{props.data.t.read("new_rule")}</h3>
                <form className="" method="post" onSubmit={(e) => {
                    handleSaveRule(e)
                }}>
                    <div id="rule-container">
                        <div>
                            <div className="mb-2">
                                <div className=" row pb-2">
                                    <label className="col-2">
                                        {props.data.t.read("rule.name")}:
                                    </label>
                                    <div className="col-4">
                                        <input type="text" onFocus={e => e.target.select()} required
                                               className="form-control"
                                               placeholder={props.data.t.read("rule.name")}
                                               value={selectedRule.name}
                                               onChange={(e) => updateRule("name", e)}/>
                                    </div>
                                </div>

                                <div className=" row pb-2">
                                    <label className="col-2">
                                        {props.data.t.read("rule.status")}:
                                    </label>
                                    <div className="col-2">
                                        <select className="form-control"
                                                value={selectedRule.active ? "1" : "0"}
                                                onChange={(e) => updateRule("active", e)}>
                                            <option
                                                value="1">{props.data.t.read("rule.active")}</option>
                                            <option
                                                value="0">{props.data.t.read("rule.inactive")}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className=" row pb-2">
                                    <label className="col-2">
                                        {props.data.t.read("rule.priority")}:
                                    </label>
                                    <div className="col-1">
                                        <input type="number" min="1" max="100"
                                               className="form-control"
                                               value={selectedRule.priority ?? 1}
                                               onChange={e => updateRule("priority", e)}/>
                                    </div>
                                </div>

                                <div className="row pb-2">
                                    <label className="col-2">
                                        Description :
                                    </label>
                                    <div className="col-8">
                                                            <textarea type="text" required className="form-control"
                                                                      placeholder="Description"
                                                                      value={selectedRule.description}
                                                                      onChange={(e) => updateRule("description", e)}/>
                                    </div>
                                </div>
                            </div>

                            <h4>
                                Conditions
                                <button className='btn btn-primary btn-xs ml-2 pl-1 pr-1'
                                        type="button"
                                        onClick={() => addCondition()}
                                >
                                    <FontAwesomeIcon icon={faPlus}/>
                                </button>
                            </h4>
                            <div className="mb-1">
                                {props.data.t.read("rule.after_invoice_complete")}...
                            </div>
                            {
                                selectedRule.conditions.map((c, idx) => {
                                    return <div key={"c-" + c.id} className="mb-2">
                                        <div className=" row">
                                            <h5 className=" pt-2 col-1 d-flex ">
                                                <div className={"w-25 mr-2"}>
                                                    {selectedRule.conditions.length > 1 &&
                                                        <FontAwesomeIcon
                                                            className=""
                                                            icon={faMinusSquare} type="button"
                                                            color={"red"}
                                                            onClick={() => removeCondition(c)}
                                                        />}
                                                </div>
                                                <div className={"mr-1"}>
                                                    {
                                                        idx > 0 && selectedRule.conditions.length > 1 ?
                                                            props.data.t.read("rule.and") + " "
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div>
                                                    {props.data.t.read("rule.if")}...
                                                </div>


                                            </h5>
                                            <div className="col-4">
                                                <select className="form-control" value={c.type}
                                                        onChange={(e) => updateCondition("type", c, e)}>
                                                    <option
                                                        value="amount_lower">... {props.data.t.read("rule.the_amount_is_lower")} ...
                                                    </option>
                                                    <option
                                                        value="amount_greater">... {props.data.t.read("rule.the_amount_is_greater")} ...
                                                    </option>
                                                    <option
                                                        value="supplier_number">... {props.data.t.read("rule.the_supplier_number_equals")} ...
                                                    </option>
                                                    <option
                                                        value="supplier_number_not_equal">... {props.data.t.read("rule.the_supplier_number_not_equals")} ...
                                                    </option>
                                                    <option
                                                        value="cost_type">... {props.data.t.read("rule.the_cost_type_equals")} ...
                                                    </option>
                                                    <option
                                                        value="cost_type_not_equal">... {props.data.t.read("rule.the_cost_type_not_equals")} ...
                                                    </option>
                                                    <option
                                                        value="client_number">... {props.data.t.read("rule.the_client_number_equals")} ...
                                                    </option>
                                                    <option
                                                        value="gl_unit_number">... {props.data.t.read("rule.the_gl_unit_number_equals")} ...
                                                    </option>
                                                    <option
                                                        value="client_number_not_equal">... {props.data.t.read("rule.the_client_number_not_equals")} ...
                                                    </option>
                                                    <option
                                                        value="invoice_billable">... {props.data.t.read("rule.invoice_is_set_as")} ...
                                                    </option>
                                                    <option
                                                        value="invoice_custom">... {props.data.t.read("rule.invoice_is_custom")} ...
                                                    </option>
                                                    <option
                                                        value="supplier_name_equals">... {props.data.t.read("rule.supplier_name_equals")} ...
                                                    </option>
                                                    <option
                                                        value="gl_unit_is">... {props.data.t.read("rule.gl_unit_is")} ...
                                                    </option>
                                                    <option
                                                        value="gl_unit_is_not">... {props.data.t.read("rule.gl_unit_is_not")} ...
                                                    </option>
                                                    <option
                                                        value="item_description">... {props.data.t.read("rule.item_description")} ...
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="col-7 row">
                                                {
                                                    c.type === "item_description" &&
                                                    <div className='col-3'>
                                                        <select className="form-control"
                                                                value={c.operator}
                                                                onChange={(e) => updateCondition("operator", c, e)}>
                                                            <option
                                                                value="contain">{props.data.t.read("rule.contain")}</option>
                                                            <option
                                                                value="not_contain">{props.data.t.read("rule.not_contain")}</option>
                                                            <option
                                                                value="equal">{props.data.t.read("rule.equal")}</option>
                                                            <option
                                                                value="different">{props.data.t.read("rule.different")}</option>
                                                        </select>
                                                    </div>
                                                }
                                                <div className="col-5">
                                                    {
                                                        c.type === "invoice_billable" ?
                                                            <select className="form-control"
                                                                    value={c.value}
                                                                    onChange={(e) => updateCondition("value", c, e)}>
                                                                <option
                                                                    value="1">{props.data.t.read("rule.billable")}</option>
                                                                <option
                                                                    value="0">{props.data.t.read("rule.non_billable")}</option>
                                                            </select>
                                                            :
                                                            c.type === "invoice_custom" ?
                                                                <select className="form-control"
                                                                        value={c.value}
                                                                        onChange={(e) => updateCondition("value", c, e)}>
                                                                    <option
                                                                        value="1">{props.data.t.read("rule.invoice_custom")}</option>
                                                                    <option
                                                                        value="0">{props.data.t.read("rule.invoice_not_custom")}</option>
                                                                </select>
                                                                :
                                                                <input type="text"
                                                                       className="form-control"
                                                                       placeholder={props.data.t.read("rule.value")}
                                                                       value={c.value}
                                                                       onChange={(e) => updateCondition("value", c, e)}/>
                                                    }
                                                </div>
                                                <label className="col-7 font-xsmall">
                                                    {
                                                        c.type === "supplier_number" ?
                                                            "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                            :
                                                            c.type === "gl_unit_number" ?
                                                                "Ex: 14, or a list : 4, A, 14, Montreal"
                                                                :
                                                                c.type === "cost_type" || c.type === "cost_type_not_equal" ?
                                                                    "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                                    :
                                                                    c.type === "client_number" || c.type === "client_number_not_equal" ?
                                                                        "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                                        :
                                                                        null
                                                    }
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            <h4>
                                Actions:
                                <button className='btn btn-primary btn-xs ml-2 pl-1 pr-1'
                                        type="button"
                                        onClick={() => addAction()}
                                >
                                    <FontAwesomeIcon icon={faPlus}/>
                                </button>
                            </h4>
                            {
                                selectedRule.actions.map((c, idx) => {
                                    return <div key={"a-" + c.id} className="mb-1">
                                        <div className="workflow-line row ">

                                            <h5 className="col-1 d-flex pt-2">
                                                <div className={"w-25 mr-2"}>
                                                    {selectedRule.actions.length > 1 &&
                                                        <FontAwesomeIcon
                                                            className=""
                                                            icon={faMinusSquare} type="button"
                                                            color={"red"}
                                                            onClick={() => removeAction(c)}
                                                        />}
                                                </div>
                                                <div className={"align-text-center"}>
                                                    {
                                                        idx > 0 && selectedRule.actions.length > 1 ?
                                                            props.data.t.read("rule.and")
                                                            :
                                                            props.data.t.read("rule.then")
                                                    }
                                                </div>
                                            </h5>
                                            <div className="col-4">
                                                <select className="form-control" value={c.type}
                                                        onChange={(e) => updateAction("type", c, e)}>
                                                    <option
                                                        value="send_email">... {props.data.t.read("rule.send_email_to")} ...
                                                    </option>
                                                    <option
                                                        value="split_items">... {props.data.t.read("rule.split_items")} ...
                                                    </option>
                                                    <option
                                                        value="only_keep_total">... {props.data.t.read("rule.only_keep_total")} ...
                                                    </option>
                                                    <option
                                                        value="change_currency">... {props.data.t.read("rule.change_currency_for")} ...
                                                    </option>
                                                    <option
                                                        value="change_all_totals">... {props.data.t.read("rule.change_all_totals")} ...
                                                    </option>
                                                    <option
                                                        value="change_user">... {props.data.t.read("rule.change_user")} ...
                                                    </option>
                                                    <option
                                                        value="change_item_description">... {props.data.t.read("rule.change_item_description")} ...
                                                    </option>
                                                    <option
                                                        value="change_item_detail">... {props.data.t.read("rule.change_item_detail")} ...
                                                    </option>
                                                    <option
                                                        value="change_status">... {props.data.t.read("rule.change_status")} ...
                                                    </option>
                                                    <option
                                                        value="request_approval">... {props.data.t.read("rule.request_approval")} ...
                                                    </option>
                                                </select>
                                            </div>
                                            {
                                                c.type === "request_approval" &&
                                                <>
                                                    <div className=''>
                                                        <FormSelectUser
                                                            chosenOption={approvers.find(user => user?.id_user === c?.value)}
                                                            options={approvers.sort((a, b) => a["first_name"] + a["last_name"] > b["first_name"] + b["last_name"] ? 1 : -1)}
                                                            optionChanged={option => {
                                                                setApprover(option)
                                                                let event = {"target": {"value": option.id_user}}
                                                                updateAction("value", c, event)
                                                            }}/>
                                                    </div>
                                                </>
                                            }
                                            {
                                                c.type === "change_status" &&
                                                <>
                                                    <div className='col-2'>
                                                        <select className="form-control"
                                                                value={c.value}
                                                                onChange={(e) => updateAction("value", c, e)}>
                                                            {
                                                                props.data.config.invoice_status.map(tmp => {
                                                                    return <option key={tmp}
                                                                                   value={tmp}>{props.data.t.read("status." + tmp.toLowerCase())}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </>

                                            }
                                            {
                                                c.type === "change_item_detail" &&
                                                <>
                                                    <div className='col-2'>
                                                        <select className="form-control"
                                                                value={c.field}
                                                                onChange={(e) => updateAction("field", c, e)}>
                                                            <option
                                                                value="GLNatural">{props.data.t.read("gl_natural")}</option>
                                                            <option
                                                                value="GLOffice">{props.data.t.read("office")}</option>
                                                            <option
                                                                value="CostType">{props.data.t.read("invoice.cost_type")}</option>
                                                            <option value="Description">Description
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-4">
                                                        <input type="text" required
                                                               className="form-control"
                                                               placeholder={c.field} value={c.value}
                                                               onChange={(e) => updateAction("value", c, e)}/>
                                                    </div>
                                                </>

                                            }
                                            {
                                                c.type === "send_email" &&
                                                <div className="col-7 row">
                                                    <div className="col-4">
                                                        <input type="email" required
                                                               className="form-control"
                                                               placeholder={props.data.t.read("rule.email")}
                                                               value={c.value}
                                                               onChange={(e) => updateAction("value", c, e)}/>
                                                    </div>
                                                    <label className="col-8 font-xsmall">
                                                        Ex: me@my-company.com
                                                    </label>
                                                </div>
                                            }

                                            {c.type === "change_currency" &&
                                                <div className="col-7 row">
                                                    <div className="col-4">
                                                        <input type="text" required
                                                               className="form-control"
                                                               placeholder={props.data.t.read("rule.currency")}
                                                               value={c.value}
                                                               onChange={(e) => updateAction("value", c, e)}/>
                                                    </div>
                                                    <label className="col-8 font-xsmall">
                                                        Ex: CAD
                                                    </label>
                                                </div>
                                            }
                                            {
                                                c.type === "split_items" &&
                                                <div className="col-7">
                                                    <div>
                                                        {
                                                            c.value.map((line, idx) => {
                                                                return <div key={"l-" + line.id}
                                                                            className="d-flex mb-2  p-1">
                                                                    <div className={""}>
                                                                        {c.value.length > 1 &&
                                                                            <FontAwesomeIcon
                                                                                className=""
                                                                                icon={faMinusSquare}
                                                                                type="button"
                                                                                color={"red"}
                                                                                onClick={() => removeSplitItemValue(c, line)}
                                                                            />}
                                                                    </div>

                                                                    <div
                                                                        className="ml-1 row border">
                                                                        <div className="col-11">
                                                                            <div
                                                                                className="row mb-1">
                                                                                <div
                                                                                    className="col-4 font-weight-bold">
                                                                                    {
                                                                                        idx === 0 ?
                                                                                            props.data.t.read("rule.a_line_containing")
                                                                                            :
                                                                                            props.data.t.read("rule.and_add_line")
                                                                                    }
                                                                                </div>
                                                                                <div
                                                                                    className="col-3">
                                                                                    <div
                                                                                        className="input-group input-group-sm">
                                                                                        <div
                                                                                            className="input-group-prepend">
                                                                                                            <span
                                                                                                                className="input-group-text">%</span>
                                                                                        </div>
                                                                                        <input
                                                                                            type="number"
                                                                                            min="-100"
                                                                                            max="1000"
                                                                                            value={line.percent}
                                                                                            onChange={e => updateSplitItemValue(c, line, "percent", e)}
                                                                                            style={{maxWidth: "100%"}}
                                                                                            className="form-control"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-3">
                                                                                    {props.data.t.read("rule.of_amount")},
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="row mb-1">
                                                                                <div
                                                                                    className="col-4">
                                                                                    {props.data.t.read("rule.up_to_max_of")}
                                                                                </div>
                                                                                <div
                                                                                    className="col-3">
                                                                                    <div
                                                                                        className="input-group input-group-sm">
                                                                                        <div
                                                                                            className="input-group-prepend">
                                                                                                            <span
                                                                                                                className="input-group-text">$</span>
                                                                                        </div>
                                                                                        <input
                                                                                            type="number"
                                                                                            value={line.maxAmount}
                                                                                            style={{maxWidth: "100%"}}
                                                                                            onChange={e => updateSplitItemValue(c, line, "maxAmount", e)}
                                                                                            className="form-control"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-5">
                                                                                    ({props.data.t.read("rule.leave_empty_for_unlimited")})
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="row mb-1">
                                                                                <div
                                                                                    className="col-4">
                                                                                    {props.data.t.read("rule.and_replace")}
                                                                                </div>
                                                                                <div
                                                                                    className="col-3">
                                                                                    <input
                                                                                        type="number"
                                                                                        min="-100"
                                                                                        max="1000"
                                                                                        value={line.updateCharacterAmount}
                                                                                        onChange={e => updateSplitItemValue(c, line, "updateCharacterAmount", e)}
                                                                                        style={{maxWidth: "100%"}}
                                                                                        className="form-control"/>
                                                                                </div>
                                                                                <div
                                                                                    className="col-3">
                                                                                    {props.data.t.read("rule.character_at")}
                                                                                </div>
                                                                                <div
                                                                                    className="col-2">
                                                                                    <select
                                                                                        className="form-control form-control-sm"
                                                                                        value={line.replaceAtTheStart}
                                                                                        onChange={e => updateSplitItemValue(c, line, "replaceAtTheStart", e)}>
                                                                                        <option
                                                                                            value={false}>{props.data.t.read("rule.the_end")}</option>
                                                                                        <option
                                                                                            value={true}>{props.data.t.read("rule.the_start")}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="row mb-1">
                                                                                <div
                                                                                    className="col-4">
                                                                                    {props.data.t.read("rule.of")}
                                                                                </div>
                                                                                <div
                                                                                    className="col-3">
                                                                                    <select
                                                                                        className="form-control form-control-sm"
                                                                                        value={line.updateInfo}
                                                                                        onChange={e => updateSplitItemValue(c, line, "updateInfo", e)}>
                                                                                        <option
                                                                                            value="">--
                                                                                        </option>
                                                                                        <option
                                                                                            value="GLNatural">GLNatural
                                                                                        </option>
                                                                                        <option
                                                                                            value="GLOffice">GLOffice
                                                                                        </option>
                                                                                        <option
                                                                                            value="CostType">CostType
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                                {
                                                                                    line.updateInfo != null && line.updateInfo.length > 0 &&
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-1">
                                                                                            {props.data.t.read("rule.by")}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-3">
                                                                                            <input
                                                                                                type="text"
                                                                                                required
                                                                                                className="form-control form-control-sm"
                                                                                                placeholder="Value"
                                                                                                value={line.updateInfoValue}
                                                                                                onChange={e => updateSplitItemValue(c, line, "updateInfoValue", e)}/>
                                                                                        </div>
                                                                                    </React.Fragment>

                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div>
                                                        <button type="button"
                                                                className="btn btn-success"
                                                                onClick={() => addSplitItemValue(c)}>
                                                            + {props.data.t.read("rule.line")}
                                                            {/*{props.data.t.read("rule.add_line")}*/}
                                                        </button>
                                                    </div>
                                                </div>

                                            }
                                            {
                                                c.type === "change_all_totals" &&
                                                <div className="input-group-prepend">
                                                    <input type="number" min="-100" max="100"
                                                           value={c.value}
                                                           onChange={e => updateAction("value", c, e)}
                                                           style={{maxWidth: "100%"}}
                                                           className="form-control"/>
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            }
                                            {
                                                c.type === "change_user" &&
                                                <div className="input-group-prepend">
                                                    <input type="text" value={c.value}
                                                           onChange={e => updateAction("value", c, e)}/>
                                                </div>
                                            }
                                            {
                                                c.type === "change_item_description" &&
                                                <div className='col-4'>
                                                    <input type="text" value={c.value}
                                                           className="form-control"
                                                           placeholder="Description"
                                                           onChange={e => updateAction("value", c, e)}/>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="mt-5 d-flex justify-content-between align-items-end">
                            <div>
                                <button type="submit" disabled={selectedRule.saved}
                                        className="btn btn-lg btn-primary">{props.data.t.read("learn")}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}

        </div>
    )
}

export default RuleEdit