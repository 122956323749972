import React, { useState, useEffect, useRef } from 'react';

const formatMoney = (value, locale) => {
  if (!value) return '';
  
  let numberValue = value;

  if(isNaN(numberValue))
    if(locale === 'en-US')
      numberValue = parseFloat(numberValue.replace(/,/g, ''));
    else
      numberValue = parseFloat(numberValue.replace(/,/g, '.'));

    if (isNaN(numberValue)) return '';
    
  return `${new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(numberValue)} km`;
};

const DistanceInput = ({ value, onChange, locale = 'en-US', placeholder = "" }) => {
  const [inputValue, setInputValue] = useState(formatMoney(value || '', locale));
  const [selected, setSelected] = useState(false);
  const inputRef = useRef(null);  // Add ref to input


  const handleInputChange = (e) => {
    let { value } = e.target;

    if(locale === "fr-FR")
        value = value.replace(/,/g, '.')

    value = value.replace(/[^-0-9.]/g, '');

    setInputValue(value);
  };

  // Sync inputValue with value prop when it changes
  useEffect(() => {
    setInputValue(formatMoney(value || '', locale));
  }, [value, locale]);

  const handleBlur = () => {
    setSelected(false)
    const formattedValue = formatMoney(inputValue, locale);
    if (formattedValue === '')
        return 

    setInputValue(formattedValue);
    
    if(inputValue === value)
        return

    const val = parseFloat(inputValue);

    if(isNaN(val))
      onChange(null);
    else
      onChange(val);

  };

  const handleFocus = () => {
    setSelected(true);
    const input = inputRef.current;

    if (input) {
      // Select all text when input is focused
      input.select();
    }
    
  }

  return     <input
              ref={inputRef}  // Attach ref to the input element
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleBlur}
              placeholder={placeholder}
              onFocus={handleFocus}
              className={`form-control-sm form-control ${selected ? "field-selected" : "field-unselected"}`}
            />
};

export default DistanceInput;
