import {Link} from "react-router-dom";
import React from "react";

const ExpenseNavigationBar = ({data, page}) => {
    return (
        <div className="navbar mb-4">
            <div className="d-flex nav-tabs ">
                <div
                    className={page === "imputations" ? "nav-item active custom-border-right" : "nav-item custom-border-right"}>
                    <Link to="/expenses_configuration/imputations" className=" nav-link">
                        <span>{data.t.read("imputations")}</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ExpenseNavigationBar;
