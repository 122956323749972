import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import Utils from "../utils/utils";
import Modal from '../components/modal';
import FormSelectUser from "../components/form-select-user";
import { fetch } from '../utils/api-wrapper';
import FormTextfield from "../components/form-textfield";
import XpenseList from "../components/xpense-list";
import Expense from "../components/expense";
import PerdiemAndMileageList from "../components/perdiem-and-mileage-list";

const ExpensesAccountCreation = ({ data }) => {
    const source = axios.CancelToken.source();
    const { account_id } = useParams(); // Access the id parameter from the URL
    const [expensesAccount, setExpensesAccount] = useState();
    const [showRequestApproval, setShowRequestApproval] = useState(false);
    const [currentExpense, setCurrentExpense] = useState(null);
    const [savingError, setSavingError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [action, setAction] = useState(null);
    const [approver, setApprover] = useState(null);
    const [approvers, setApprovers] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const timeoutId = useRef(null);
    const navigate = useNavigate(); // Hook to navigate programmatically

    const [previousStatus, setPreviousStatus] = useState(null);

    const fetchExpensesAccount = () => {
        const searchData = `?account_id=${encodeURIComponent(account_id)}`;

        fetch(`/expenses_account/${searchData}`, "GET", {},
            (response) => {
                setExpensesAccount(response.data.expenses_account);
                if (response.data.expenses_account.expenses.find(x => !['mileage', 'perdiem'].includes(x.json?.type) && ["Pending", "Processing"].includes(x.status)) !== undefined)
                    timeoutId.current = setTimeout(
                        () => fetchExpensesAccount(), 3000
                    );
                else
                    clearInterval(timeoutId.current);
            }
            ,
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchExpensesAccount();
            }
            , false, source.token);
    }

    useEffect(() => {
        fetchExpensesAccount();
    }
        , [account_id])

    useEffect(() => {
        setPreviousStatus(expensesAccount?.status)

        if (!expensesAccount?.status || previousStatus === null || previousStatus === undefined)
            return;

        if (previousStatus !== expensesAccount.status)
            setSuccessMessage(`${data.t.read('expenses_account')} ${data.t.read(`expenses_account_status.${expensesAccount.status.toLowerCase()}`)}`);

        setPreviousStatus(expensesAccount.status)
    }
        , [expensesAccount?.status])

    useEffect(() => {
        fetchApprover();
        return () => {
            source.cancel('expenses_account_creation unmounted');
            clearInterval(timeoutId.current);
        }
    }, [])

    const closeInvoicePrompt = () => {
        fetchExpensesAccount();
        setCurrentExpense(null);
    }

    const submit = () => {
        setSaving(true)
        setAction('submit');
        fetch("/expense_account/submit/" + account_id, "PUT", null,
            response => {
                setSaving(false);
                navigate(`/expenses_accounts/`);
            },
            error => {
                setSaving(false);
                if (error.response?.status !== 500 && error.response?.data?.message)
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                else
                    setSavingError(data.t.read("internal_saving_error"));
            })
    }

    const updateExpenseAccount = (update) => {
        setSaving(true)
        setAction('update_expense_account');
        fetch("/expense_account/" + account_id, "PUT", update,
            response => {
                if (response?.data?.expenses_account)
                    setExpensesAccount(response?.data?.expenses_account);
                setSaving(false);
            },
            error => {
                setSaving(false);
                if (error.response?.status !== 500 && error.response?.data?.message)
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                else
                    setSavingError(data.t.read("internal_saving_error"));
            })
    }

    const fetchApprover = () => {
        fetch("/client/users/Approver", "GET", {},
            response => {
                setApprovers(response.data);
                setApprover(response.data ? response.data[0] : null);
            })
    }

    const request_approval = () => {
        setSaving(true);
        setAction('request_approval');
        const payload = {
            approver: approver,
            status: 'Need_Approval',
            status_details: new Date().toLocaleString() + ' : ' + data.firstName + ' ' + data.lastName + ' : ' + data.t.read("request_approval") + " " + data.t.read("sent") + " " + data.t.read("to") + " " + approver["last_name"] + " " + approver["first_name"] + '\n' + expensesAccount?.status_details
        }
        fetch("/expense_account/approval/" + account_id, "PUT", payload,
            response => {
                setSaving(false);
                navigate(`/expenses_accounts/`);
            },
            error => {
                setSaving(false);
                setShowRequestApproval(false);
                if (error.response.status !== 500 && error.response?.data?.message)
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                else
                    setSavingError(data.t.read("internal_saving_error"));
            })
    }

    const disApproveExpense = (expense, causeDenial) => {
        expense.status = 'Denied';
        expense.status_details = new Date().toLocaleString() + ' : ' + data.firstName + ' ' + data.lastName + ' : ' + causeDenial + '\n' + expense?.status_details;
        return updateExpense(expense);
    }

    const approveExpense = (expense) => {
        expense.status = 'Approved';
        return updateExpense(expense);
    }

    const validateExpense = (expense) => {
        setAction('validating');
        const formData = new FormData();
        formData.append("expense", JSON.stringify(expense));
        // Return a Promise to ensure we have a resolved value
        return new Promise((resolve) => {
            fetch(`/expenses/validate/${account_id}/${expense.checksum}`,
                "PUT",
                formData,
                response => {
                    resolve(true); // Resolve with true on success
                },
                error => {
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                    resolve(false); // Resolve with false on error
                }
            );
        });
    }

    const updateExpense = (expense, action = 'update_approval') => {
        const formData = new FormData();
        setAction(action);
        formData.append("json", JSON.stringify(expense.json));
        formData.append("status_details", expense.status_details);
        formData.append("status", expense.status);
        return new Promise((resolve) => {
            fetch("/expenses/" + account_id + "/" + expense.checksum, "PUT", formData,
                response => {
                    setAction(null);
                    resolve(true);
                },
                error => {
                    setAction(null);
                    resolve(false);
                    setSavingError(data.t.read("internal_saving_error"))
                })
        });
    }

    return <div className="card p-1 mt-5 rounded-lg">
        <div className="card-body">
            <div className="card-title d-flex justify-content-center">
                <h2>{data.t.read("expenses_account")}</h2>
            </div>
            <div className="d-flex justify-content-end m-1">
                {Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data, ['Admin', 'Approver']) && <button type="button" className="btn btn-secondary mr-1 btn-secondary-shadow" disabled={saving | ["Pending", "Finalized"].includes(expensesAccount?.status)} onClick={() => setShowRequestApproval(true)}>
                    <div className="action-buttons action-buttons-sm">
                        {data.t.read("request_approval")}
                        {saving && action === 'request_approval' && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                    </div>
                </button>}
                <button type="button" className="btn btn-primary btn-primary-shadow" disabled={saving | expensesAccount?.status !== "Pending"} onClick={() => submit()}>
                    <div className="action-buttons action-buttons-sm">
                        {data.t.read("submit")}
                        {saving && action === 'submit' && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                    </div>
                </button>

            </div>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="information-panel-content"
                    id="information-panel-header"
                >
                    <h3>Information</h3>

                </AccordionSummary>
                <AccordionDetails>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Id :</label>
                        </div>
                        <div className="col-6">
                            <span>{account_id}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("statusTitle")} :</label>
                        </div>
                        <div className="col-6">
                            <span> {data.t.read(`expenses_account_status.${expensesAccount?.status.toLowerCase()}`)}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.currency")} :</label>
                        </div>
                        <div className="col-6">
                            <span>{expensesAccount?.currency}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label> {data.t.read("number_of_receipts")} :</label>
                        </div>
                        <div className="col-6">
                            <span>{expensesAccount?.expenses?.length}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Description :</label>
                        </div>
                        <div className="col-5">
                            <FormTextfield locked={saving} value={expensesAccount?.description} validateChange={val => updateExpenseAccount({ 'description': val })} />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="finances-panel-content"
                    id="finances-panel-header"
                >
                    <h3>{data.t.read("summary")}</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense {data.t.read("invoice.sub_total")} :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {expensesAccount?.Total?.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.tax")}1 :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {expensesAccount?.Tax1?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.tax")}2 :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {expensesAccount?.Tax2?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense Taxes :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {expensesAccount?.Tax?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("tips")} :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {expensesAccount?.Tip?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense {data.t.read("invoice.total")} :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {expensesAccount?.BigTotalPayed?.toFixed(2)}</span>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <XpenseList expensesAccount={expensesAccount} fetchExpensesAccount={fetchExpensesAccount} saving={saving} approveExpense={approveExpense} disApproveExpense={disApproveExpense} currentExpense={currentExpense} setCurrentExpense={setCurrentExpense} validateExpense={validateExpense} />
            <PerdiemAndMileageList setSaving={setSaving} expensesAccount={expensesAccount} fetchExpensesAccount={fetchExpensesAccount} saving={saving} setCurrentExpense={setCurrentExpense} approveExpense={approveExpense} disApproveExpense={disApproveExpense} validateExpense={validateExpense} />
        </div>
        {
            savingError &&
            <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                <div className="text-danger">
                    {savingError}
                </div>
            </Modal>

        }
        {
            successMessage &&
            <Modal title="Success" okText={"OK"} okAction={() => { setSuccessMessage(null); navigate(`/expenses_accounts/`); }}>
                <p className='alert-success'>{successMessage}</p>
            </Modal>
        }
        {
            showRequestApproval &&
            <Modal overflow="visible" invalid={approver === null} title={data.t.read("request_approval")} cancelAction={() => setShowRequestApproval(false)} okAction={() => request_approval()} okText={data.t.read("submit")} cancelText={data.t.read("cancel")}>
                <FormSelectUser chosenOption={expensesAccount?.approver} options={approvers.sort((a, b) => a["first_name"] + a["last_name"] > b["first_name"] + b["last_name"] ? 1 : -1)} optionChanged={option => { setApprover(option) }} />
            </Modal>
        }
        {currentExpense && <div className="overlay" onClick={() => closeInvoicePrompt(false)}>
            <div className="overlay-content">
                <div className="invoice-container" onClick={e => { e.preventDefault(); e.stopPropagation() }}>
                    <Expense account_status={expensesAccount.status} account_id={account_id} selected={currentExpense} data={data} close={() => { setCurrentExpense(null); fetchExpensesAccount(); }} />
                </div>
            </div>
        </div>
        }
    </div>
}
export default inject('data')(observer(ExpensesAccountCreation));