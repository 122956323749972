import React, { useState, useEffect } from 'react';
import GraphLine from '../components/graph-line';
import GraphBar from '../components/graph-bar';
import Utils from '../utils/utils';
import TableData from '../components/table-data';
import TableRules from "../components/table-rules";
import Invoice from '../components/invoice';
import Select from 'react-select';
import { observer, inject } from 'mobx-react';

import { fetch } from '../utils/api-wrapper';

const Monitor =  ({data}) =>  {

    const styleSelect = {
        control: (baseStyles, state) => ({
        ...baseStyles,
        border: "0 !important",
        boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    }

    const [selected, setSelected] = useState(null);
    const [currentInvoice, setCurrentInvoice] = useState(null);
    const [invoiceCount, setInvoiceCount] = useState(0);
    const [amountTotal, setAmountTotal] = useState(0);
    const [monthlyProcessedCount, setMonthlyProcessedCount] = useState([]);
    const [monthlyProcessedCountSec, setMonthlyProcessedCountSec] = useState([]);
    const [monthlyProcessedTotal, setMonthlyProcessedTotal] = useState([]);
    const [monthlyProcessedByUserUsers, setMonthlyProcessedByUserUsers] = useState([]);
    const [monthlyProcessedByUserTotal, setMonthlyProcessedByUserTotal] = useState([]);
    const [usersList, setUsersList] = useState([]);

    const selectionColumn = [
        { "name": "json.InvoiceNo", "evaluate": inv => inv.json ? inv.json.InvoiceNo : data.t.read("n_a"), "displayName": "invoices", "filter": "json.InvoiceNo", searchable: true },
        { "name": "json.InvoiceDate", "evaluate": inv => inv.json ? inv.json.InvoiceDate : data.t.read("n_a"), "displayName": "invoice.billing_date" },
        { "name": "json.DueDate", "evaluate": inv => inv.json && inv.json.DueDate ? inv.json.DueDate : data.t.read("n_a"), "displayName": "invoice.due_date" },
        { "name": "json.Total", "evaluate": inv => inv.json && inv.json.Total ? inv.json.Total : data.t.read("n_a"), "displayName": "invoice.total", "money": true }
    ]

    if (Utils.userHasPageAccess(data, "approval"))
        selectionColumn.push({ "name": "approver", "evaluate": inv => inv.json && inv.json.approver ? inv.json.approver.first_name + " " + inv.json.approver.last_name : data.t.read("n_a"), "displayName": "invoice.approver" })
    if (Utils.userHasPageAccess(data, "payments"))
        selectionColumn.push({ "name": "payment_status", "evaluate": inv => inv.payment ? inv.payment.status : data.t.read("n_a"), "displayName": "invoice.payment_status" })

    const vendorsColumn = [
        { "name": "supplier_name", "evaluate": inv => inv?.supplier_name ? `${inv.supplier_name}` : "", "displayName": "vendors", "filter": "json.ListSupplier.0.Number", "money": false, searchable: true },
        { "name": "MTD", "evaluate": inv => inv?.MTD ? inv.MTD : data.t.read("n_a"), "displayName": "MTD", "money": true, "acronym": "acronym.MTD" },
        { "name": "YTD", "evaluate": inv => inv?.YTD ? inv.YTD : data.t.read("n_a"), "displayName": "YTD", "money": true, "acronym": "acronym.YTD" },
        { "name": "BYTD", "evaluate": inv => inv?.BYTD ? inv.BYTD : data.t.read("n_a"), "displayName": "BYTD", "money": true, "acronym": "acronym.MTD" },
        { "name": "PY", "evaluate": inv => inv?.PY ? inv.PY : data.t.read("n_a"), "displayName": "PY", "money": true, "acronym": "acronym.PY" }
    ]
    const vendorsVariance = [
        { "name": "supplier_name", "evaluate": inv => inv?.supplier_name ? `${inv.supplier_name}` : "", "displayName": "vendors", "filter": "json.ListSupplier.0.Number", "money": false, searchable: true },
        { "name": "MTD", "evaluate": inv => inv?.MTD ? inv.MTD : data.t.read("n_a"), "displayName": "MTD", "money": true, "acronym": "acronym.MTD" },
        { "name": "AVGM", "evaluate": inv => inv?.AVGM ? inv.AVGM : data.t.read("n_a"), "displayName": "AvgM", "money": true, "acronym": "acronym.AvgM" },
        { "name": "VARIANCE", "evaluate": inv => inv?.VARIANCE ? `${inv.VARIANCE.toFixed(2)}%` : data.t.read("n_a"), "displayName": "Variance", "money": false }
    ]

    const glsColumn = [{ "name": "_id", "evaluate": inv => inv?._id ? `${inv._id}` : "", "displayName": "gl", "filter": "json.ListItem.GLNatural", "money": false, searchable: true },
        ...vendorsColumn.filter(c => c.displayName !== "vendors")]

    const vendorsRules = [
        {
            "name": "supplier_name",
            "evaluate": rule => rule?.supplier?.name ? `${rule.supplier.name}` : "",
            "displayName": "vendors",
            "searchable": true
        },
        {
            "name": "Description",
            "evaluate": rule => rule?.conditions[1]?.value,
            "displayName": "description"
        },
        {
            "name": "New GlNatural",
            "evaluate": rule => rule?.actions[0]?.field === "GLNatural" ? rule?.actions[0]?.value : "N/A",
            "displayName": "new_gl_natural"
        },
        {
            "name": "New Office",
            "evaluate": rule => rule?.actions[0]?.field === "GLOffice" ? rule?.actions[0]?.value : "N/A",
            "displayName": "new_gl_office"
        },
        ...(data.config.type === "legal" ? [
            {
                "name": "New CostType",
                "evaluate": rule => rule?.actions[0]?.field === "CostType" ? rule?.actions[0]?.value : "N/A",
                "displayName": "new_cost_type"
            }
        ] : [])

    ]
    useEffect(() => {
        fetchData("");
        fetchUsers();
    }, []);

    const fetchData = (username_selected) => {
        fetch(`/stats/monitor?username_selected=${username_selected}`, "GET", {}, response => {
            const [users, usersTotal] = getUsersUsersTotal(response);
            dropdownSetState(response, usersTotal, users);
        }, null, false);
    }

    const fetchUsers = () => {
        fetch(`/client/usersDetail`, "GET", {}, response => {
            setUsersList([...response.data]);
        });
    }

    const getUsersUsersTotal = (response) => {
        let users = [];
        let usersTotal = []

        for (let username in response.data["invoices_per_user"]) {
            users.push(username);
            usersTotal.push(response.data["invoices_per_user"][username]);
        }
        return [users, usersTotal]
    }

    const dropdownSetState = (response, usersTotal, processedByUsersUsers) => {
        setInvoiceCount(response.data["invoice_count"]);
        setAmountTotal(parseInt(response.data["invoice_total"]));
        setMonthlyProcessedByUserUsers(processedByUsersUsers);
        setMonthlyProcessedByUserTotal(usersTotal);
        setMonthlyProcessedCount(response.data["monthly_processed"]);
        setMonthlyProcessedTotal(response.data["monthly_amounts"]);
    }

    const onChangeDropdownUser = (value) => {
        fetchData(value)
    }

    return <div className="monitor-page">
            <div className="d-flex justify-content-between mb-4 pl-2">

                {/* Dropdown user selection */}
                <div className="d-flex flex-3">
                    <div className="mr-2 col-3">
                        <Select
                                    name="user"
                                    options={[, {value: '', label: '---'}, ...usersList.map( u => ({value: u.username, label: u.first_name +" "+ u.last_name }) )]}
                                    styles={styleSelect}
                                    classNamePrefix="select"
                                    placeholder={data.t.read("user")}
                                    onChange={(choice) => onChangeDropdownUser(choice.value)}
                        />
                    </div>
                </div>
            </div>
            {/* Line of figures */}
            <div className="d-flex justify-content-between">
                <div className="rounded-box">
                    <div className="huge-number">{invoiceCount}</div>
                    <div className="graph-data-label">{data.t.read("invoiceProcessedCount")}</div>
                </div>
                <div className="rounded-box">
                    <div className="huge-number">$ {amountTotal.toLocaleString()}</div>
                    <div className="graph-data-label">{data.t.read("invoiceProcessedTotal")}</div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="rounded-box">
                    <div style={{ height: 200 }}>
                        {monthlyProcessedCount.length > 0 ?
                            <GraphLine dataset={monthlyProcessedCount}
                                secondaryDataSet={monthlyProcessedCountSec} showYAxis={true}
                                color="#fe6888" id="monthly-processed" hardLine={true}
                                labels={[data.t.read('month.jan').toUpperCase(), data.t.read('month.feb').toUpperCase(), data.t.read('month.mar').toUpperCase(), data.t.read('month.apr').toUpperCase(), data.t.read('month.may').toUpperCase(), data.t.read('month.jun').toUpperCase(), data.t.read('month.jul').toUpperCase(), data.t.read('month.aug').toUpperCase(), data.t.read('month.sep').toUpperCase(), data.t.read('month.oct').toUpperCase(), data.t.read('month.nov').toUpperCase(), data.t.read('month.dec').toUpperCase()]} /> : null}
                    </div>
                    <div className="graph-data-label">{data.t.read("invoiceProcessedCount")}</div>
                </div>
                <div className="rounded-box">
                    <div style={{ height: 200 }}>
                        {monthlyProcessedTotal.length > 0 ?
                            <GraphBar dataset={monthlyProcessedTotal} horizontal={true} showYAxis={true}
                                label="$" id="monthly-invoice-processed"
                                color={["#ccb2ff", "#9ad0f5", "#a5dfdf", "#ffe198", "#9ad0f5", "#ffb1c1"]}
                                labels={[data.t.read('month.jan').toUpperCase(), data.t.read('month.feb').toUpperCase(), data.t.read('month.mar').toUpperCase(), data.t.read('month.apr').toUpperCase(), data.t.read('month.may').toUpperCase(), data.t.read('month.jun').toUpperCase(), data.t.read('month.jul').toUpperCase(), data.t.read('month.aug').toUpperCase(), data.t.read('month.sep').toUpperCase(), data.t.read('month.oct').toUpperCase(), data.t.read('month.nov').toUpperCase(), data.t.read('month.dec').toUpperCase()]} /> : null}
                    </div>
                    <div className="graph-data-label">{data.t.read("invoiceProcessedTotal")}</div>
                </div>
            </div>
            {Utils.userHasPageAccess(data, "reporting") &&
                <>
                    <div className="d-flex justify-content-between">
                        <TableRules title={"Proposed Rules"} data={data} availableColumns={vendorsRules}/>
                        <TableData title={data.t.read("vendor_actuals")} t={data.t} availableColumns={vendorsColumn}
                                   url="/dataset/vendor_actuals" selection={(s) => setSelected(s)}/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <TableData title={data.t.read("account_actuals")} t={data.t} availableColumns={glsColumn}
                                   url="/dataset/gl_actuals" selection={(s) => setSelected(s)}/>
                        <TableData title={data.t.read("vendor_variance")} t={data.t} availableColumns={vendorsVariance} url="/dataset/vendor_variance" selection={(s) => setSelected(s)} />

                    </div>
                            {
                                selected && <div className="overlay" onClick={() => setSelected(null)}>
                                <div className='d-flex'>
                                        <div onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation()
                                        }}>
                                            <TableData selection={(s) => setCurrentInvoice(s)} showButton={true}
                                                       close={() => setSelected(null)}
                                                       className="card col m-2 min-width-1000 min-height-70 border-radius-30"
                                                       title={selected.title} t={data.t} availableColumns={selectionColumn}
                                                       url={selected.url} filters={selected.filters} pageSize={10}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                currentInvoice &&
                                <div className="overlay" onClick={() => setCurrentInvoice(null)}>
                                    <div className="overlay-content">
                                        <div onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation()
                                        }}>

                                            <Invoice id={currentInvoice} invoices={[]} close={() => {
                                                setCurrentInvoice(null);
                                                setSelected(null);
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }

                </div>
            }

        export default inject('data')(observer(Monitor));
